a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p,
span,
em {
    font-family: 'Mulish-Regular';
    line-height: 1.5;
    font-variation-settings: 'wght' var(--body-weight);
}

em {
    font-family: 'Mulish-Regular';
}

h1,
h2,
h3,
h4 {
    font-family: 'Mulish-Regular';
    font-variation-settings: 'wght' var(--heading-weight);
    line-height: 1.5;
}

h5 {
    font-family: 'Mulish-Regular';
}

h6 {
    font-family: 'Mulish-Regular';
    margin: 0;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.black {
    color: black !important;
}

.red {
    color: var(--color-red) !important;
}

.bolder {
    font-variation-settings: 'wght' var(--bold-weight);
}

.link {
    text-decoration: underline;
}

#main {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

#bg {
    opacity: 0.75;
    position: fixed;
    /*height: 100%;
    width: 100%;
    */
}

.App {
    text-align: center;
    overflow-x: hidden;
}

.contain {
    position: relative;
    display: inline-block;
    left: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
    background-color: var(--color-navy);
    z-index: 100;
    -moz-appearance: initial;
}

.contain:before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 20%;
    left: -20%;
    background-color: var(--color-red);
    -moz-appearance: initial;
}

.updown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5em;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: -10px;
}

.updown > span {
    position: relative;
    opacity: 1;
}

.interaction-dramatic {
    position: relative;
    color: white;
    padding-left: 0.4em;
    padding-right: 0.4em;
    font-size: 2em;

    overflow-y: hidden;
    overflow-x: hidden;
    & > span {
        position: relative;
        left: -200%;
        font-family: 'Anurati';
    }
}

/*Landing CSS*/
.Landing-container {
    /*display: inline-block;*/
    position: relative;
    pointer-events: none;
}

.Landing {
    position: relative;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100vh;
    top: 0;
    width: 100%;
    align-self: center;
    /*background: url(./landing_bg.svg) no-repeat;
    background-size: 20%;
    background-position: 110% 120%;*/
    pointer-events: auto;
}

.Landing:after {
    content: '';
    opacity: 1;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: url(../assets/me2_comp2.jpg) no-repeat;
    background-size: 50%;
    background-position: 90% 120%;
    transition: all 2s ease;
}

.Landing:before {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(../assets/landing_element_min.svg) no-repeat;
    background-size: 35%;
    background-position: 100% 100%;
}

.imageOpacity:after {
    opacity: 0;
    transition: all 2s ease;
}

.heading-contain {
    position: relative;
    height: auto;
    width: auto;
    pointer-events: auto;
}

.blurb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mihir {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    // font-family: 'Futura';
    font-size: 5em;
}

.mihir > span {
    position: relative;
    left: -200%;
}

.mihirgif {
    opacity: 1 !important;
    height: 4.5em;
    width: auto;
    opacity: 1;
}

.mihirgif-container {
    opacity: 1 !important;
}

.subhead {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    pointer-events: auto;
    z-index: 0;
}

.subhead-word {
    font-size: 1.05em;
    height: 1.5em;
    position: relative;
    opacity: 0.75;
    overflow-y: hidden;
    display: flex;
    justify-content: left;
    text-align: left;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: var(--color-red);
    padding-right: 0.5em;
}

.subhead-word > span {
    position: relative;
    opacity: 0.75;
    top: -100%;
    font-variation-settings: 'wght' 570;
}

.landing-description {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 1em;
    pointer-events: auto;
    justify-content: left;
    text-align: left;
}

.landing-line {
    font-size: 1.1em;
    height: 1.5em;
    position: relative;
    overflow-y: hidden;
    display: flex;
    // justify-content: left;
    // text-align: left;
    // align-items: left;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.landing-line > span {
    position: relative;
    opacity: 0.75;
    top: 100%;
}

.section-header {
    color: white;
    font-size: 2em;
    padding-left: 1em;
    padding-right: 1em;
}

.landing {
    &-text {
        padding: 2em;
        text-align: left;
    }
    &-header {
        font-size: 1.1em;
    }
}

/*Organizations*/

.works-container {
    height: auto;
    width: 100%;
    position: relative;
    background-color: var(--color-navy);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    pointer-events: auto;
}

.works-container:before {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(../assets/la_skyline_new.svg) no-repeat;
    background-size: 110%;
    background-position: 0% 105%;
}

.works-content {
    padding-top: 4em;
    padding-bottom: 4em;
    padding-bottom: 25em;
}

.workmain-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 80vw;
    height: auto;
    padding-left: 4em;
    padding-right: 4em;
    flex-direction: column;
    flex-wrap: wrap;
}

.org-logos-contain {
    display: flex;
    position: relative;
    width: 70%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.current-work {
    display: flex;
    width: 70%;
    height: auto;
    text-align: center;
    padding: 1em;
    padding-bottom: 3em;
}

.org {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 9em;
    height: 9em;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
    transition: 0.2s ease all;
    cursor: pointer;
}

.org img {
    position: relative;
    top: 0%;
    display: block;
    margin: auto;
    width: 60%;
    height: 60%;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.org-description {
    display: flex;
    flex-direction: column;
    padding-top: 0.7em;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
    background-color: var(--color-red);
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0%;
    height: 100%;
    width: 100%;
    color: white;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.org:hover .org-description {
    top: 0%;
}

.org:hover.org img {
    top: -100%;
}

.org:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/*
Landing About
*/

/*
WRITES PAGE 
*/

/*
Projects
*/

.projects-container {
    height: auto;
    padding-top: 2em;
    padding-bottom: 4em;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: white;
    pointer-events: auto;
    flex-direction: column;
    box-sizing: border-box;

    .projects-description {
        margin: 0.5em 4em;
        align-self: start;
    }

    h1 {
        font-size: var(--font-size-xxl);
        color: black;
    }

    .projects-line {
        align-self: start;
        color: black;
        font-size: var(--font-size-large);
    }
}

.projects-container .section-header,
.projects-container h4 {
    color: black;
}

/*For mobile screens.*/

@media (max-width: 486px) {
    #bg {
        height: 100%;
        width: 100%;
    }
    .Landing {
        top: -5em;
        margin-bottom: -5em;
    }

    .blurb {
        position: absolute;
        left: calc(100vw - 300px);
        transform: scale(0.75);
        top: 45vh;
    }
    // .align-selfription {
    //     align-items: center;
    //     justify-content: center;
    //     text-align: center;
    //     width: 100vw;
    //     flex-direction: column;
    //     align-self: center;
    // }
    .heading-contain {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        align-items: baseline;
    }
    .mihir {
        font-size: 3em;
    }
    .subhead {
        width: 100vw;
    }

    // .landing-line {
    //     width: 100vw;
    //     justify-content: center;
    //     text-align: center;
    //     align-items: center;
    // }
    .Landing:after {
        background-size: 65%;
        background-position: 50% 105%;
        opacity: 0.1;
    }
    .current-work {
        width: 80vw;
    }
    .org-logos-contain {
        width: 85vw;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }
    .org {
        width: 7em;
        height: 7em;
    }
    .works-content {
        padding-bottom: 10em;
    }
    .works-container:before {
        background-position: 0% 100%;
    }
}

/*For tablet screens*/

@media (min-width: 486px) and (max-width: 768px) {
    #bg {
        height: 100%;
        width: 100%;
    }
    .Landing {
        top: -5em;
        margin-bottom: -5em;
    }
    .Landing:after {
        background-size: 60%;
        background-position: 100% 100%;
    }
    .blurb {
        position: absolute;
        left: calc(100vw - 350px);
        // transform: scale(0.75);
        top: 45vh;
    }
    .workmain-contain {
        width: 100vw;
    }
}

/*For tablet screens*/
@media (min-width: 768px) {
    .landing {
        &-text {
            padding: 1em var(--padding-left-xl);
        }
        &-header {
            font-size: 1.4em;
        }
    }
    .projects-description {
        margin: 0.5em 4em;
        width: 40%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    #bg {
        height: 100%;
        width: 100%;
    }
    .Landing {
        top: -5em;
        margin-bottom: -5em;
    }
    .Landing:after {
        background-size: 60%;
        background-position: 100% 100%;
    }
    .workmain-contain {
        width: 100vw;
    }
}

/*For large screens.*/

@media (min-width: 1024px) {
    #bg {
        /*  height: 90vh;*/
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .heading-contain {
        position: absolute;
        left: calc(100vw - var(--gif-right-offset));
        top: var(--gif-top-offset);
    }
    .landing-description {
        position: absolute;
        left: calc(100vw - var(--gif-right-offset));
        top: calc(var(--gif-top-offset) + 115px);
    }
    .subhead {
        position: absolute;
        left: calc(100vw - var(--gif-right-offset));
        top: calc(var(--gif-top-offset) + 75px);
    }
    .landing-line {
        width: 50vw;
    }
}

@media (min-width: 1440px) {
    .workmain-contain {
        width: 60vw;
    }
}
