.projDesc {
    position: absolute;
    top: 0%;
    left: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 2em 0em 2em;
    transform: scale(1.1);
    background-color: rgba(3, 29, 51, 0.95);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.projImg {
    width: 30em;
    height: auto;
}

@media (max-width: 486px) {
    .projImg {
        width: 20em;
        height: auto;
    }
    .projDesc p {
        font-size: 0.7em;
    }
}
