.colorchange {
    background: white !important;
}

.colorchange:before {
    background: white !important;
}

.colorchange:after {
    background: white !important;
}

.menu {
    position: absolute;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    top: 0;
    width: 100%;
    pointer-events: auto;
}

.menu-button {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    overflow-x: hidden;
    position: fixed;
    top: 3em;
    right: 3em;
    height: 10vh;
    z-index: 5;
    opacity: 0;
}

.menu-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    overflow-x: hidden;
    flex-direction: column;
    left: 100%;
    width: 100%;
    height: 100vh;
    z-index: 4;
    background-color: #2f363b;
    overflow-y: hidden;

    &-image {
        height: 100vh;
        width: 99.5vw;
        position: absolute;
        opacity: 0;
        z-index: -1;
        transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) all;
        transform: scale(1.1);
        &-HOME {
            background: url(../assets/mihir_cover.jpg);
            background-size: cover;
        }

        &-PROJECTS {
            background: url(../projects/nomad.jpg) no-repeat;
            background-position: center;
        }

        &-READS {
            background: url(../assets/books.jpg) no-repeat;
            background-size: cover;
        }
        &-WRITES {
            background: url(../assets/writes.jpg) no-repeat; //Photo by <a href="https://unsplash.com/@kellysikkema?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Kelly Sikkema</a>
            background-size: cover;
        }
        &-DESIGN {
            background: url(../assets/design.jpg) no-repeat;
            background-size: cover;
        }
        &-VIDEOS {
            background: url(../design/design.jpg) no-repeat;
            background-size: cover;
        }
        &-PHOTOS {
            background: url(../assets/duoro.jpg) no-repeat;
            background-size: cover;
        }
    }
}

.menu-item {
    height: 2em;
    color: white;
    font-size: 1.5em;
    letter-spacing: 5px;
    position: relative;
    opacity: 0.75;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    left: 0;
    & a {
        font-variation-settings: 'wght' var(--heading-weight);
    }
}

.menu-item > span:before {
    content: '';
    background: white;
    width: 0em;
    height: 0.2em;
    position: absolute;
    top: 30%;
    left: -1.5em;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu-item > span {
    position: relative;
    opacity: 0.75;
    top: 100%;
}

.menu-item:hover.menu-item > span:before {
    width: 1em;
}

.menu-item:hover {
    left: 40px;
    opacity: 1;
    cursor: pointer;
}
