.landing-projects {
    &-container {
        height: auto;
        padding-top: 2em;
        width: 100%;
        position: relative;
        background-color: var(--color-black);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        color: white;
        pointer-events: auto;
        flex-direction: column;
        padding-bottom: 6em;
    }
}

.featured-projects-contain {
    height: auto;
    width: 100vw;
    padding-bottom: 3em;
    flex-wrap: wrap;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    pointer-events: auto;
}

.featured-project {
    position: relative;
    width: auto;
    height: auto;
    background-color: white;
    margin: 2em;
    box-sizing: border-box;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.featured-project:hover {
    transform: scale(1.1);
}

.featured-project:hover .projDesc {
    top: 0%;
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 486px) {
    .featured-projects-contain {
        flex-direction: column;
    }
}

@media (min-width: 486px) and (max-width: 768px) {
    .featured-projects-contain {
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .featured-projects-contain {
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .landing-projects-header {
        padding: 1em var(--padding-left-xl);
    }
}
