@font-face {
    font-family: 'Mulish-Regular';
    src: url('fonts/Mulish-VariableFont_wght.woff2') format('woff2'),
        url('fonts/Mulish-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anurati';
    src: url('fonts/Anurati-Regular.woff') format('woff'),
        url('fonts/Anurati-Regular.woff2') format('woff2'),
        url('fonts/Anurati-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Garamond';
    src: url('fonts/Adobe Garamond Pro Regular.ttf') format('truetype');
    font-display: swap;
}

:root {
    --color-navy: #031d33;
    --color-red: #ea4141;
    --color-black: #0f0d0d;
    --color-teal: #53d4c7;
    --color-yellow: #eec643;
    --color-primary-100: #fff;
    --color-text-white: #fff;
    --font-family-transition: 'Anurati';
    --transition-linear: linear 0.2s;
    --transition-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
    --gif-right-offset: 35vw;
    --gif-top-offset: 40vh;
    --heading-weight: 830;
    --body-weight: 220;
    --bolder-weight: 420;
    --bold-weight: 780;
    --padding-left-xl: 14vw;
    --font-size-small: 12px;
    --font-size-medium: 14px;
    --font-size-large: 18px;
    --font-size-xl: 32px;
    --font-size-xxl: 64px;
    --font-size-xxxl: 96px;
}

html {
    height: 100%;
}

::selection {
    color: white;
    background: var(--color-red);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: var(--color-navy);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-red);
}

// ::-webkit-scrollbar-button {
//     background-color: #2980b9;
// }

body {
    font-family: 'Mulish-Regular';
    letter-spacing: 0.4px;
    font-size: 16px;
    margin: 0;
    padding: 0;
}
