@import './animation.scss';

.videos {
    // ::-webkit-scrollbar {
    //     display: none;
    // }
    &-container {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: var(--color-black);
        display: grid;
        grid-template-rows: 70vh 30vh;
        animation: fadeIn 3s cubic-bezier(0.67, 0, 0.34, 0.83);
    }

    &-player {
        position: relative;
        display: grid;
        place-items: center;
    }

    &-iframe {
        height: calc(95vw * 9 / 16);
        width: 95vw;
    }

    &-carousel {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        align-items: center;
        background-color: var(--color-text-white);
        // justify-content: center;
        box-shadow: 1px -3px 37px -3px rgba(143, 135, 143, 1);
        ::-webkit-scrollbar {
            display: none;
        }

        &-item {
            margin: 1em;
            cursor: pointer;
        }
        img {
            width: 230px;
            height: auto;
        }
    }
}

@media (min-width: 768px) and (orientation: portrait) {
    .videos-iframe {
        height: calc(95vw * 9 / 16);
        width: 95vw;
    }

    .videos-carousel img {
        min-width: 300px;
        height: auto;
        width: 20vw;
    }
}

@media (min-width: 768px) and (orientation: landscape) {
    .videos-iframe {
        height: 70vh;
        width: calc(70vh * 16 / 9);
    }

    .videos-carousel img {
        width: 300px;
        height: auto;
    }
}

@media (min-width: 1024px) {
}

@media (min-width: 1024px) and (orientation: portrait) {
}
