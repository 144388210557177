@import './animation.scss';

.gallery-photo {
    display: flex;
    position: absolute;
    top: 0;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2% 0;
    align-items: center;
    height: 90vh;
    --container-width: 95%;

    &-description {
        padding-top: 20px;
        width: 100%;
    }
    &-title {
        color: white;
        animation: fadeIn 1s cubic-bezier(0.67, 0, 0.34, 0.83);
        font-size: var(--font-size-large);
        font-variation-settings: 'wght' var(--heading-weight);
    }
    &-location {
        color: white;
        position: relative;
        font-size: var(--font-size-medium);
        font-variation-settings: 'wght' var(--body-weight);
        animation: fadeIn 1s cubic-bezier(0.67, 0, 0.34, 0.83);
        animation-delay: 0.1s;
    }

    img {
        max-width: var(--container-width);
        max-height: 90%;
        animation: fadeIn 0.5s cubic-bezier(0.67, 0, 0.34, 0.83);
    }
}

.grid-photo {
    display: flex;
    margin: 20px 0;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    --description-height: 150px;

    img {
        width: 100%;
        cursor: pointer;
    }

    &-description-overlay {
        position: absolute;
        // bottom: calc(-1 * var(--description-height));
        bottom: 0;
        left: 0;
        opacity: 0.5;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        width: 100%;
        height: var(--description-height);
        box-sizing: border-box;
        padding: 0 2em 0em 2em;
        background: rgb(0, 0, 0);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.6) 25%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(255, 255, 255, 0) 100%
        );
        color: white;
        transition: all 0s cubic-bezier(0.215, 0.61, 0.355, 1);
        cursor: pointer;
    }

    &-title {
        color: white;
        opacity: 0;
        position: relative;
        left: -10px;
        font-size: var(--font-size-large);
        font-variation-settings: 'wght' var(--heading-weight);
        transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        // transition-delay: 0.3s;
    }

    &-location {
        color: white;
        opacity: 0;
        position: relative;
        left: -10px;
        font-size: var(--font-size-medium);
        font-variation-settings: 'wght' var(--body-weight);
        transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-delay: 0.1s;
    }

    &:hover {
        .grid-photo-description-overlay {
            opacity: 1;
            bottom: 0px;
        }
        .grid-photo-title {
            left: 0px;
            opacity: 1;
        }
        .grid-photo-location {
            left: 0px;
            opacity: 1;
        }
    }
}

.grid-photo-mobile {
    margin-bottom: 1em;

    img {
        width: 100%;
        -webkit-box-shadow: 0px -1px 23px -10px rgba(0, 0, 0, 0.55);
        box-shadow: 0px -1px 23px -10px rgba(0, 0, 0, 0.55);
    }

    &-description {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        width: 100%;
        padding: 0.5em 1em 2em 1em;
        box-sizing: border-box;
    }

    &-title {
        font-size: var(--font-size-large);
        font-variation-settings: 'wght' var(--heading-weight);
    }

    &-location {
        font-size: var(--font-size-medium);
        font-variation-settings: 'wght' var(--body-weight);
    }
}

// @media (max-width: 486px) {
//     .grid-photo {
//         margin: 0px 0;

//     }
// }
