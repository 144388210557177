@import './animation.scss';

.reads-container {
    position: absolute;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    top: 0;
    align-self: center;
    pointer-events: auto;
    background-color: white;
    padding: 10em 10% 10em 20%;
    animation: fadeIn 3s cubic-bezier(0.67, 0, 0.34, 0.83);
}

.reads-content {
    display: block;
    position: relative;
    text-align: left;
    color: var(--color-black);
    p,
    div,
    h2,
    h4 {
        font-family: 'Adobe Garamond';
    }

    &-line {
        font-weight: normal;
        font-size: 1.2em;
    }

    &-book-container {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        text-align: left;
        font-size: 1.2em;
        line-height: 150%;
    }

    h1 {
        font-size: var(--font-size-xxxl);
        margin: 0;
    }

    h2 {
        font-weight: bolder;
        font-size: 1.3em;
        margin: 0.5em 0 0 0;
        color: var(--color-navy);
    }

    h4 {
        margin: 0 0 0.5em 0;
        font-variant: small-caps;
        font-weight: normal;
    }
}

@media (max-width: 486px) {
    .reads-container {
        padding: 5em 2em !important;
    }

    .reads-content {
        h1 {
            font-size: var(--font-size-xxl);
            margin: 0;
        }
    }
    .book {
        &-cover {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 50%;
            }
        }
    }
}

@media (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1024px) {
    .reads-container {
        // &:before {
        //     content: '';
        //     height: 100vh;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     background: url(../assets/books.jpg) no-repeat;
        //     background-size: cover;
        //     opacity: 0.07;
        // }
    }
    .reads-content-line {
        width: 60%;
    }
    .reads-content {
        &-book-container {
            display: flex;
            flex-direction: row;
        }
        h1 {
            font-size: var(--font-size-xxl);
            margin: 0;
        }
    }
    .book {
        &-details {
            width: 70%;
        }

        &-cover {
            padding-right: 5em;
            display: flex;
            align-items: center;

            img {
                width: 8em;
            }
        }
    }
}

@media (min-width: 1024px) and (orientation: portrait) {
}
