// Photos Page
@import './animation.scss';

.photos-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    pointer-events: auto;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    color: white;
    background: radial-gradient(
        circle,
        rgba(13, 13, 13, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(3, 29, 51, 1) 100%
    );
    animation: fadeIn 3s cubic-bezier(0.67, 0, 0.34, 0.83);
}

.photos-header {
    margin: 2em 10em 0 10em;
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 40%;
    h1 {
        font-size: var(--font-size-xxxl);
        margin-bottom: 0;
    }

    p {
        font-size: var(--font-size-large);
    }
}

.photos-grid {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 20px;
    justify-content: center;

    &.photos-grid-three {
        grid-template-columns: 30% 30% 30%;
        margin: 2em 6em 6em 6em;
    }

    &.photos-grid-two {
        grid-template-columns: 47% 47%;
    }
}

@media (max-width: 486px) {
    .photos-grid {
        grid-gap: 8px;
        margin: 2em 0;
    }

    .photos-header {
        margin: 4em 2em 0 2em;
        width: 80%;

        h1 {
            font-size: var(--font-size-xxl);
        }

        p {
            font-size: var(--font-size-medium);
        }
    }
}

// .photos-column {
//     &:last-child {
//         position: relative;
//         bottom: 0;
//     }
// }

.photo-viewer {
    // position: absolute;
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    background-color: #000000ee;
    animation: fadeIn 0.5s cubic-bezier(0.67, 0, 0.34, 0.83);

    button {
        cursor: pointer;
        border: 0;
        font-size: var(--font-size-large);
    }

    &-remove {
        animation: fadeOut 0.5s cubic-bezier(0.67, 0, 0.34, 0.83);
    }

    // .back-button {
    //     position: absolute;
    //     top: 5em;
    //     left: 5em;
    //     color: white;
    //     background: transparent;
    //     font-weight: var(--bold-weight);
    //     font-family: var(--font-family-transition);
    //     width: 2em;
    //     height: 2em;
    // }

    .nav-buttons-container {
        display: flex;
        position: absolute;
        bottom: 0em;
        align-items: center;
        justify-content: center;
    }

    .nav-button {
        padding: 0.5em 1em;
        margin: 0.5em;
        background-color: transparent;
        color: white;
    }
}
