@import './animation.scss';

.writes-container {
    position: absolute;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    top: 0;
    align-self: center;
    pointer-events: auto;
    background-color: white;
    padding: 10em 10% 10em 20%;
    font-size: 1.2em;
    color: var(--color-black);
    animation: fadeIn 3s cubic-bezier(0.67, 0, 0.34, 0.83);

    a,
    p,
    span,
    div,
    h2,
    h4,
    h6 {
        font-family: 'Adobe Garamond';
    }
}

.writes-content {
    position: relative;
    text-align: left;
    h1 {
        font-size: var(--font-size-xxxl);
        margin: 0;
    }
}

.write-link {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    font-weight: bolder;
    // font-size: 1.2em;
    text-decoration: underline;
}

.write-link span {
    padding-top: 1em;
    padding-bottom: 1em;
    cursor: pointer;
}

.writes-section {
    padding: 0em 0em 3em 0em;
}

.write-section-title {
    padding: 2em 0em 0em 0em;
    font-weight: bolder !important;
    font-size: 1.7em !important;
}

.small {
    font-size: 0.7em;
}

@media (max-width: 486px) {
    .writes-container {
        padding: 5em 2em;
    }

    .writes-content {
        h1 {
            font-size: var(--font-size-xxl);
        }
    }
}
